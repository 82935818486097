<script setup lang="ts">
/**
 * @author Giorgi Tseradze
 */

import { useMainStore } from "@/store/main";
import Button from "@/components/regularComponents/UIComponents/Button.vue";

const props = defineProps<{
  redirect?: boolean;
}>();

const mainStore = useMainStore();

const router = useRouter();

const handleClick = () => {
  router.push({ path: `/${mainStore.lang}` });
};

// const clearCache = async (): Promise<void> => {
//   ["ka", "en", "ru"].forEach((lang) => {
//     $fetch(`/api/${lang}?clearCache=true`, {
//       method: "POST",
//       body: {
//         clearCache: true,
//       },
//     });
//   });

//   $fetch(`/api/getIndx?clearCache=true`, {
//     method: "POST",
//     body: {
//       clearCache: true,
//     },
//   });

//   // $fetch(`/api/getContentDataL/all?clearCache=true`, {
//   //   method: "POST",
//   //   body: {
//   //     clearCache: true,
//   //   },
//   // });

//   $fetch(`/api/sitemap?clearCache=true`, {
//     method: "POST",
//     body: {
//       clearCache: true,
//     },
//   });

//   $fetch(`/api/getThirdPartyScripts?clearCache=true`, {
//     method: "POST",
//     body: {
//       clearCache: true,
//     },
//   });
// };
// clearCache();

if (props.redirect) {
  router.push({ path: `/${mainStore.lang}/404` });
}
</script>

<template>
  <section class="container error-page d-flex j-center al-center">
    <div class="error-page__container">
      <div class="error-page__title hl-1-light">
        <h1 class="uppercase">{{ $t("oops") }}</h1>
      </div>
      <div class="error-page__teaser hl-1-light">
        <h2>404</h2>
      </div>
      <div class="error-page__inner d-flex al-center hl-2">
        <p>{{ $t("something went wrong") }}</p>
      </div>
      <div class="error-page__inner-subheader d-flex al-center p-12">
        <p>{{ $t("error 404 page not found") }}</p>
      </div>
      <div @click="handleClick" class="error-page__inner-btn d-flex j-center">
        <Button class="btn" btnText="back to home"></Button>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.error-page {
  width: 100%;
  margin-top: 500px;
  @include maxq(desktop-2k) {
    margin-top: 400px;
  }

  @include maxq(desktop-lg) {
    margin-top: 120px;
    .show-more {
      margin-top: 10px;
      @include maxq(mobile) {
        margin-top: unset;
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    &-btn {
      margin-top: 50px;
      @include maxq(desktop-lg) {
        margin-top: 40px;
      }
      @include maxq(mobile) {
        margin-top: 30px;
      }
      @include maxq(mobile-sm) {
        .button {
          height: 48px;
          width: 70%;
        }
        margin-top: 28px;
      }
      .button__content {
        text-transform: initial;
        font-size: 28px;
        @include maxq(desktop-lg) {
          font-size: 15px;
        }
        @include maxq(mobile-sm) {
          font-size: 14px;
        }
      }
    }
    p {
      margin-top: 20px;
      text-align: center;
      font-size: 64px;
      @include weight(400);
      @include maxq(desktop-lg) {
        margin-top: unset;
        font-size: 32px;
      }
    }
    &-subheader {
      display: flex;
      justify-content: center;
      margin-top: 44px;
      font-size: 40px;
      @include maxq(desktop-lg) {
        margin-top: 14px;
      }
      p {
        margin-top: 10px;
        text-align: center;
        line-height: 20px;
        @include maxq(desktop-lg) {
          font-size: 15px;
        }
      }
    }

    @include maxq(mobile) {
      flex-direction: column;
    }
  }
  &__title {
    display: flex;
    justify-content: center;
    h1 {
      font-size: 132px;
      @include weight(400);
      @include maxq(desktop-lg) {
        font-size: 64px;
        line-height: 78px;
      }
      @include maxq(mobile) {
        font-size: 52px;
        line-height: 64px;
      }
      @include maxq(mobile-sm) {
        font-size: 40px;
        line-height: 52px;
      }
    }
  }
  &__teaser {
    display: flex;
    justify-content: center;
    h2 {
      font-size: 420px;
      line-height: 420px;
      margin-top: 20px;
      @include weight(700);
      @include maxq(desktop-lg) {
        margin-top: unset;
        font-size: 272px;
        line-height: 252px;
      }
      @include maxq(mobile) {
        font-size: 200px;
        line-height: 180px;
      }
      @include maxq(mobile-sm) {
        font-size: 140px;
        line-height: 130px;
      }
    }
  }
}
</style>
